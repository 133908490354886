<template>
  <md-card class="md-card-profile">
    <md-card-content>
      <h6 class="category text-gray">
        <span v-if="userInfos.user.role == 1"><i class="badge bg-secondary">CLIENT</i></span>
        <span v-if="userInfos.user.role == 2"><i class="badge bg-success">VENDOR</i></span>
        <span v-if="userInfos.user.role == 3"><i class="badge bg-danger">MANAGER</i></span>
        <span v-if="userInfos.user.role == 4"><i class="badge bg-warning">ADMIN</i></span>
      </h6>
      <h4 class="card-title">{{ userInfos.user.name }}</h4>
      <p>{{ userInfos.user.email }}</p>
    </md-card-content>
  </md-card>
</template>
<script>
export default {
  name: "user-card",
  props: {
    cardUserImage: {
      type: String,
      default: require("@/assets/img/faces/marc.jpg"),
    },
  },
  data() {
    return {
      userInfos: JSON.parse(localStorage.getItem('userData'))
    };
  },
};
</script>
<style></style>
