<template>
    <div class="content">
        <div class="mt-5 mb-5">
            <GlobalStatsPage />
        </div>
        <div class="mt-5 mb-5">
            <UserStatsPage />
        </div>
       
    </div>
</template>
<script>
import GlobalStatsPage from './globalStatsPage.vue';
import UserStatsPage from './userStatsPage.vue';

export default {
    name: "statsPage",
    components:{
        GlobalStatsPage,
        UserStatsPage
    }
}
</script>
