<template>
    <div class="auth-layout d-flex justify-content-center align-items-center vh-100 bg-light">
      <div class="card shadow p-4" style="width: 24rem;">
        <div class="card-header text-center bg-dark text-white">
          <h3 class="mb-0">LYNX</h3>
        </div>
        <div class="card-body">
          <span v-if="error" class="text-danger d-block mb-3">Email or Password Wrong!</span>
          <form @submit.prevent="login">
            <div class="mb-3">
              <label for="email" class="form-label">Email address</label>
              <input type="email" class="form-control" id="email" v-model="email" required>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input type="password" class="form-control" id="password" v-model="password" required>
            </div>
            <button type="submit" class="btn btn-dark w-100">Login</button>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import { API_URL } from '../../services/apis'
  
  
  export default {
    name: 'LoginPage',
    data() {
      return {
        email: '',
        password: '',
        error:false
      }
    },
    methods: {
      async login() {
        try {
          const response = await axios.post(API_URL+'/login', {
            email: this.email,
            password: this.password
          })
  
          if (response.data.success) {
            localStorage.setItem('userData', JSON.stringify(response.data.data))
            localStorage.setItem('isConnected', '1')
            localStorage.setItem('token', response.data.data.token)
            this.$notify({
                message:
                "Welcome!",
                icon: "add_alert",
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success',
            });
            this.$router.push({ name: 'Dashboard' }) // Ensure this router method is called correctly
          } else {
            localStorage.setItem('userData', JSON.stringify(response.data.data))
            localStorage.setItem('isConnected', '0')
            localStorage.setItem('token', '')
            // Handle login failure
          }
        } catch (error) {
          if(error.response.data.data.error){
            this.error = true;
          }
          localStorage.setItem('userData', JSON.stringify({}))
          localStorage.setItem('isConnected', '0')
          localStorage.setItem('token', '')
         // console.error('An error occurred:', error.response.data.data.error)
        }
      }
    }
  }
  </script>
  
  <style scoped>
 .auth-layout {
  background-color: #f5f5f5;
  font-family: 'Roboto', sans-serif;
}
.card {
  background-color: white;
  border: none;
  border-radius: 10px;
}
.card-header {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.card-title {
  color: #333;
}
  </style>
  