<template>
    <div class="my-5 ml-5 mr-5">
        <div class="row justify-content-center text-center">
            <div class="">
                <div class="card shadow-lg">
                    <div class="card-header bg-primary text-white text-center">
                        <h3>Edit Product</h3>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="saveDetails">
                            <div class="mb-3">
                                <label for="name" class="form-label">Name</label>
                                <input type="text" class="form-control" id="name" v-model="form.name" required>
                            </div>
                            <div class="mb-3">
                                <label for="code" class="form-label">Code</label>
                                <input type="text" class="form-control" id="code" v-model="form.code" required>
                            </div>
                            <div class="mb-3">
                                <label for="ugs" class="form-label">UGS</label>
                                <input type="text" class="form-control" id="ugs" v-model="form.ugs" required>
                            </div>
                            <div class="mb-3">
                                <label for="short_description" class="form-label">Short Description</label>
                                <ckeditor class="ckedito-perso" label="Short Description"
                                    v-model="form.short_description" :editor="editor" :config="editorConfig"></ckeditor>
                            </div>
                            <div class="mb-3">
                                <label for="description" class="form-label">Description</label>
                                <ckeditor class="ckedito-perso" label="Description" v-model="form.description"
                                    :editor="editor" :config="editorConfig"></ckeditor>
                            </div>
                            <div class="mb-3">
                                <label for="prix_fournisseur" class="form-label">Cost</label>
                                <input type="number" class="form-control" id="prix_fournisseur"
                                    v-model="form.prix_fournisseur" required>
                            </div>
                            <div class="mb-3">
                                <label for="prix_old" class="form-label">Old Price</label>
                                <input type="number" class="form-control" id="prix_old" v-model="form.prix_old"
                                    required>
                            </div>
                            <div class="mb-3">
                                <label for="prix" class="form-label">Price</label>
                                <input type="number" class="form-control" id="prix" v-model="form.prix" required>
                            </div>
                            <div class="mb-3">
                                <label for="meta_title" class="form-label">Meta Title</label>
                                <input type="text" class="form-control" id="meta_title" v-model="form.meta_titre"
                                    required>
                            </div>
                            <div class="mb-3">
                                <label for="meta_description" class="form-label">Meta Description</label>
                                <textarea class="form-control" id="meta_description" v-model="form.meta_description"
                                    required></textarea>
                            </div>
                            <button type="submit" class="btn btn-primary w-100">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center mt-5">
            <div>
                <div class="card shadow-lg">
                    <div class="card-header bg-primary text-white text-center">
                        <h3>Categories</h3>
                    </div>
                    <div class="card-body">
                        <md-button class="md-primary" data-bs-toggle="modal"
                            data-bs-target="#addCategoryToProduitModal">
                            <md-icon>add</md-icon>
                        </md-button>
                        <div class="modal fade" id="addCategoryToProduitModal" tabindex="-1"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Add Categorie</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <form @submit.prevent="saveCategoryToProduit">
                                            <select class="form-select" v-model="catValue">
                                                <option selected disabled value="">Choose Category</option>
                                                <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{
                            cat.name }}</option>
                                            </select>
                                            <button type="submit" class="btn btn-primary w-100">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <md-table v-model="productDataCategories" table-header-color="black">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell md-label="Name">{{ item.name }} - {{ item.id }}</md-table-cell>
                                <md-table-cell md-label="Actions">

                                    <md-button class="md-danger" @click="deleteCategoryFromProduit(item.id)">
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center mt-5">
            <div>
                <div class="card shadow-lg">
                    <div class="card-header bg-primary text-white text-center">
                        <h3>Tags</h3>
                    </div>
                    <div class="card-body">
                        <md-button class="md-primary" data-bs-toggle="modal" data-bs-target="#addTagToProduitModal">
                            <md-icon>add</md-icon>
                        </md-button>
                        <div class="modal fade" id="addTagToProduitModal" tabindex="-1"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Add Tag</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <form @submit.prevent="saveTagToProduit">
                                            <select class="form-select" v-model="tagValue">
                                                <option selected disabled value="">Choose Tag</option>
                                                <option v-for="tg in tags" :key="tg.id" :value="tg.id">{{ tg.name }}
                                                </option>
                                            </select>
                                            <button type="submit" class="btn btn-primary w-100">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <md-table v-model="productDataTags" table-header-color="black">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell md-label="Name">{{ item.name }} - {{ item.id }}</md-table-cell>
                                <md-table-cell md-label="Actions">

                                    <md-button class="md-danger" @click="deleteTagFromProduit(item.id)">
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center mt-5">
            <div>
                <div class="card shadow-lg">
                    <div class="card-header bg-primary text-white text-center">
                        <h3>Picture</h3>
                    </div>
                    <div class="card-body">
                        <md-button class="md-primary" data-bs-toggle="modal" data-bs-target="#addPictureToProduit">
                            <md-icon>add</md-icon>
                        </md-button>
                        <div class="modal fade" id="addPictureToProduit" tabindex="-1"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Add picture</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <form enctype="multipart/form-data">
                                            <div class="input-group mb-3">
                                                <label class="input-group-text" for="inputGroupFile01">Upload</label>
                                                <input type="file" class="form-control" id="inputGroupFile01"
                                                    @change="handleFileChange">
                                            </div>
                                            <md-button class="md-danger" @click="saveImage()">
                                                <md-icon>add</md-icon>
                                            </md-button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="picture">
                            <img :src="storageUrl+'/' + picture" style="width:200px;height:200px;" />

                        </div>
                        <div v-else>
                            <span>no picture yet!</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center mt-5">
            <div>
                <div class="card shadow-lg">
                    <div class="card-header bg-primary text-white text-center">
                        <h3>Images</h3>
                    </div>
                    <div class="card-body">
                        <md-button class="md-primary" data-bs-toggle="modal" data-bs-target="#addPictureSecondaireToProduit">
                            <md-icon>add</md-icon>
                        </md-button>
                        <div class="modal fade" id="addPictureSecondaireToProduit" tabindex="-1"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Add Picture Secondaire</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <form enctype="multipart/form-data">
                                            <div class="input-group mb-3">
                                                <label class="input-group-text" for="inputGroupFile01">Upload</label>
                                                <input type="file" class="form-control" id="inputGroupFile01"
                                                    @change="handleFileSecondaireChange">
                                            </div>
                                            <md-button class="md-danger" @click="saveImageSecondaire()">
                                                <md-icon>add</md-icon>
                                            </md-button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <md-table v-model="images" table-header-color="black">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell md-label="Picutre">
                                    <img :src="storageUrl+'/' + item.path"
                                        style="width:100px; height:100px;" />
                                </md-table-cell>
                                <md-table-cell md-label="Actions">
                                    <md-button class="md-danger" @click="deleteImage(item.id)">
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL,STORAGE_URL } from '../../services/apis';
import Editor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: 'ProduitDetailsPage',
    data() {
        return {
            storageUrl:STORAGE_URL,
            produit: [],
            tags: [],
            categories: [],
            stocks: [],
            editor: Editor,
            editorData: '<p>Hello YOUR TEXT HERE</p>',
            editorConfig: {
                // CKEditor configuration options
            },
            form: {
                name: '',
                code: '',
                ugs: '',
                description: 'Fill the description',
                short_description: 'Fill the short description',
                prix_fournisseur: 0,
                prix_old: 0,
                prix: 0,
                meta_titre: '',
                meta_description: 'Text',
                categorie: [],
                tag: [],
                stock: '',
                qty: 0
            },
            productId: null,
            productDataCategories: [],
            productDataTags: [],
            picture: [],
            images: [],
            catValue: 0,
            tagValue: 0,
            avatar: null,
            avatarSecondaire:null,
        };
    },
    created() {
        this.productId = this.$route.params.id;
        this.getProduit()
        this.getCategoriesTags()
        this.getCategories()
        this.getTags()
    },
    methods: {
        async getProduit() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/show/${this.productId}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });
                const data = await response.json();
                this.produit = data.data
                this.form.name = data.data[0].name
                this.form.code = data.data[0].code;
                this.form.ugs = data.data[0].ugs;
                this.form.description = data.data[0].description;
                this.form.short_description = data.data[0].short_description;
                this.form.prix_fournisseur = data.data[0].prix_fournisseur;
                this.form.prix_old = data.data[0].prix_old;
                this.form.prix = data.data[0].prix;
                this.form.meta_titre = data.data[0].meta_titre;
                this.form.meta_description = data.data[0].meta_description;
            } catch (error) {
                //console.error('Error fetching product data:', error);
            }
        },
        async saveDetails() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/update/${this.productId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(this.form),
                });
                if (response.ok) {
                    this.$notify({
                        message:
                            "Details updated",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'warning',
                    });
                    //console.log('Category attached successfully');
                    this.getProduit();
                } else if (response.status === 409) {
                    //console.error('Category already attached');
                    // Handle conflict, maybe display error message
                } else {
                    //console.error('Failed to attach category');
                    // Handle other errors, maybe display error message
                }
            } catch (error) {
                //console.error('Error attaching category:', error);
                throw error;
            }
        },
        async getCategoriesTags() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/categories/${this.productId}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });
                const data = await response.json();
                //console.log('inside table')
                //console.log(productData.value.data[0].categories)
                this.productDataCategories = data.data[0].categories;
                this.productDataTags = data.data[0].tags;
                this.picture = data.data[0].picture;
                this.images = data.data[0].imageproduits;
            } catch (error) {
                //console.error('Error fetching product data:', error);
            }
        },
        async getCategories() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/categorie/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    this.categories = data.data
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //   console.error('Error fetching users:', error);
                throw error;
            }
        },
        async getTags() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/tag/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    this.tags = data.data
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //   console.error('Error fetching users:', error);
                throw error;
            }
        },
        async saveCategoryToProduit() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/categorie/add/${this.productId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(this.catValue),
                });
                if (response.ok) {
                    this.$notify({
                        message:
                            "Category attached",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'bottom',
                        type: 'success',
                    });
                    //console.log('Category attached successfully');
                    this.getCategoriesTags()
                } else if (response.status === 409) {
                    //console.error('Category already attached');
                    this.$notify({
                        message:
                            "Category aleardy attached",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'bottom',
                        type: 'warning',
                    });
                    // Handle conflict, maybe display error message
                } else {
                    this.$notify({
                        message:
                            "Category attached FAILED",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'bottom',
                        type: 'danger',
                    });
                    //console.error('Failed to attach category');
                    // Handle other errors, maybe display error message
                }
            } catch (error) {
                //console.error('Error attaching category:', error);
                throw error;
            }
        },
        async deleteCategoryFromProduit(catId) {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/categorie/delete/${this.productId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(catId),
                });
                if (response.ok) {
                    this.$notify({
                        message:
                            "Category deleted",
                        icon: "delete_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'bottom',
                        type: 'danger',
                    });
                    this.getCategoriesTags()
                } else if (response.status === 409) {
                    //console.error('Category already attached');
                    // Handle conflict, maybe display error message
                } else {
                    //console.error('Failed to delete category');
                    // Handle other errors, maybe display error message
                }
            } catch (error) {
                //console.error('Error removing stand:', error);
                throw error;
            }
        },
        async saveTagToProduit() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/tag/add/${this.productId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(this.tagValue),
                });
                if (response.ok) {
                    this.$notify({
                        message:
                            "Tag attached",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'bottom',
                        type: 'success',
                    });
                    //console.log('Category attached successfully');
                    this.getCategoriesTags()
                } else if (response.status === 409) {
                    //console.error('Category already attached');
                    this.$notify({
                        message:
                            "Tag aleardy attached",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'bottom',
                        type: 'warning',
                    });
                    // Handle conflict, maybe display error message
                } else {
                    this.$notify({
                        message:
                            "Tag attached FAILED",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'bottom',
                        type: 'danger',
                    });
                    //console.error('Failed to attach category');
                    // Handle other errors, maybe display error message
                }
            } catch (error) {
                //console.error('Error attaching category:', error);
                throw error;
            }
        },
        async deleteTagFromProduit(tagId) {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/tag/delete/${this.productId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(tagId),
                });
                if (response.ok) {
                    this.$notify({
                        message:
                            "Tag deleted",
                        icon: "delete_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'bottom',
                        type: 'danger',
                    });
                    this.getCategoriesTags()
                } else if (response.status === 409) {
                    //console.error('Category already attached');
                    // Handle conflict, maybe display error message
                } else {
                    //console.error('Failed to delete category');
                    // Handle other errors, maybe display error message
                }
            } catch (error) {
                //console.error('Error removing stand:', error);
                throw error;
            }
        },
        handleFileChange(event) {
            this.avatar = event.target.files[0];
        },
        handleFileSecondaireChange(event) {
            this.avatarSecondaire = event.target.files[0];
        },
        async saveImage() {
            if (!this.avatar) {
                alert("Please select a file.");
                return;
            }

            const formData = new FormData();
            formData.append('image', this.avatar);

            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/picture/${this.productId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('Failed to upload image');
                }
                const data = await response.json();
                this.$notify({
                    message:
                        "Image Uploaded",
                    icon: "edit_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'bottom',
                    type: 'success',
                });
                this.getCategoriesTags()
            } catch (error) {
                //console.error('Error uploading image:', error);
            }
        },
        async deleteImage(id) {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/picture/secondaire/delete/${id}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    this.$notify({
                        message:
                            "Image Secondaire deleted",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'bottom',
                        type: 'danger',
                    });
                    //console.log('Image uploaded successfully');
                    this.getCategoriesTags()
                } else {
                    //console.error('Failed to delete image');
                    // Handle error, maybe display error message
                }
            } catch (error) {
                //console.error('Error deleting image:', error);
                // Handle error, maybe display error message
            }
        },
        async saveImageSecondaire(){
            if (!this.avatarSecondaire) {
                alert("Please select a file.");
                return;
            }

            const formData = new FormData();
            formData.append('image', this.avatarSecondaire);

            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/picture/secondaire/${this.productId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('Failed to upload image');
                }
                const data = await response.json();
                this.$notify({
                    message:
                        "Image Secondaire Uploaded",
                    icon: "edit_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'bottom',
                    type: 'success',
                });
                this.getCategoriesTags()
            } catch (error) {
                //console.error('Error uploading image:', error);
            }
        }
    }
};
</script>
<style scoped>
.container {
    margin-top: 2rem;
}

.card-header {
    background-color: #007bff;
    color: white;
}

.card {
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.ckeditor {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.5rem;
}
</style>
