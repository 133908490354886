<template>

    <div class="content">
        <div class="text-center">
            <md-button class="md-info" data-bs-toggle="modal" data-bs-target="#AddCategorieModal">Add
                Category<md-icon>add</md-icon></md-button>
        </div>
        <div class="modal fade" id="AddCategorieModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Category</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="saveAddCategory">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" v-model="form.name" required>
                                </div>
                                <div class="mb-3">
                                    <label for="description" class="form-label">description</label>
                                    <input type="text" class="form-control" id="description" v-model="form.description" required>
                                </div>
                                
                                
                                <button type="submit" class="btn bg-black text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Categories</h4>
                        <p class="category">List of categories</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <md-table v-model="categories" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-info" @click="editCategory(item)" data-bs-toggle="modal" data-bs-target="#EditCategorykModal" title="EDIT CATEGORY">
                                            <md-icon>edit</md-icon>
                                        </md-button>
                                        <md-button class="md-danger" @click="deleteCategory(item.id)" >
                                            <md-icon>delete</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="modal fade" id="EditCategorykModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update Category</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="updateCategory">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" v-model="form.name" required>
                                </div>
                                <div class="mb-3">
                                    <label for="description" class="form-label">description</label>
                                    <input type="text" class="form-control" id="description" v-model="form.description" required>
                                </div>
                                <button type="submit" class="btn bg-black text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL } from '../../services/apis';

export default {
    name: "CategoriesPage",
    data() {
        return {
            selected: [],
            categories: [],
            form: {
                name: '',
                description: ''
            }
        };
    },
    created() {
        this.getCategories();
    },
    methods: {
        async getCategories() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/categorie/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    this.categories = data.data
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
             //   console.error('Error fetching users:', error);
                throw error;
            }
        },
        async saveAddCategory() {
            const tokenLc = localStorage.getItem('token');
            const { name, description } = this.form; // Destructure form data
            const category = { name, description }; // Create user object
            try {
                const response = await fetch(API_URL + '/categorie/store', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                    body: JSON.stringify(category),
                });
                const responseData = await response.json();
                if (!response.ok) {
                    throw new Error(responseData.message || 'Failed to save user');
                }
                this.resetForm();
                this.getCategories();
                this.$notify({
                    message:
                    "Category Added",
                    icon: "add_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                document.getElementById('closeModal').click();
            } catch (error) {
               // console.error('Error adding user:', error);
                throw error;
            }
        },
        editCategory(category) {
            // Populate the form fields with the selected category's data
            this.form.id = category.id;
            this.form.name = category.name;
            this.form.description = category.description;
            // Password should not be populated for security reasons
            // Optionally, you can choose to populate it if needed
            
        },
        async updateCategory() {
            const token = localStorage.getItem('token');
            const { id, name,description } = this.form;
            const updatedCategory = { id, name,description };
            try {
                await fetch(API_URL + `/categorie/update/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(updatedCategory),
                });
                // Optionally, you can notify the user that the update was successful
                this.$notify({
                    message: "Category Updated",
                    icon: "edit",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                // Refresh the user list after update
                this.getCategories();
                // Clear the form after successful update
                this.resetForm();
            } catch (error) {
                //console.error('Error updating user:', error);
                throw error;
            }
        },
        resetForm() {
            // Reset form data to empty values
            this.form.name = '';
            this.form.description = '';
        },
        async deleteCategory(id){
            const token = localStorage.getItem('token');
            try {
                await fetch(API_URL+`/categorie/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                });
                this.getCategories();
                this.$notify({
                    message:
                    "Category deleted",
                    icon: "delete_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'danger',
                });
            } catch (error) {
                //console.error('Error removing user:', error);
                throw error;
            }
        }
    }
};
</script>