<template>

    <div class="content">
        <div class="text-center">
            <md-button class="md-info" data-bs-toggle="modal" data-bs-target="#AddStockModal">Add
                Stock<md-icon>add</md-icon></md-button>
        </div>
        <div class="modal fade" id="AddStockModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Stock</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="saveAddStock">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" v-model="form.name" required>
                                </div>
                                <div class="mb-3">
                                    <label for="location" class="form-label">Location</label>
                                    <input type="text" class="form-control" id="location" v-model="form.location" required>
                                </div>
                                
                                
                                <button type="submit" class="btn bg-black text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Stocks</h4>
                        <p class="category">List of stocks</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <md-table v-model="stocks" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
                                    <md-table-cell md-label="Location">{{ item.location }}</md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-info" @click="editStock(item)" data-bs-toggle="modal" data-bs-target="#EditStockModal" title="EDIT STOCK">
                                            <md-icon>edit</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="modal fade" id="EditStockModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update Stock</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="updateStock">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" v-model="form.name" required>
                                </div>
                                <div class="mb-3">
                                    <label for="location" class="form-label">Location</label>
                                    <input type="text" class="form-control" id="location" v-model="form.location" required>
                                </div>
                                <button type="submit" class="btn bg-black text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL } from '../../services/apis';

export default {
    name: "StocksPage",
    data() {
        return {
            selected: [],
            stocks: [],
            form: {
                name: '',
                location: ''
            }
        };
    },
    created() {
        this.getStocks();
    },
    methods: {
        async getStocks() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/stock/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    this.stocks = data.data
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
             //   console.error('Error fetching users:', error);
                throw error;
            }
        },
        async saveAddStock() {
            const tokenLc = localStorage.getItem('token');
            const { name, location } = this.form; // Destructure form data
            const stock = { name, location }; // Create user object
            try {
                const response = await fetch(API_URL + '/stock/store', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                    body: JSON.stringify(stock),
                });
                const responseData = await response.json();
                if (!response.ok) {
                    throw new Error(responseData.message || 'Failed to save user');
                }
                this.resetForm();
                this.getStocks();
                this.$notify({
                    message:
                    "Stock Added",
                    icon: "add_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                document.getElementById('closeModal').click();
            } catch (error) {
               // console.error('Error adding user:', error);
                throw error;
            }
        },
        editStock(stock) {
            // Populate the form fields with the selected stock's data
            this.form.id = stock.id;
            this.form.name = stock.name;
            this.form.location = stock.location;
            // Password should not be populated for security reasons
            // Optionally, you can choose to populate it if needed
            
        },
        async updateStock() {
            const token = localStorage.getItem('token');
            const { id, name,location } = this.form;
            const updatedStock = { id, name,location };
            try {
                await fetch(API_URL + `/stock/update/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(updatedStock),
                });
                // Optionally, you can notify the user that the update was successful
                this.$notify({
                    message: "Stock Updated",
                    icon: "edit",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                // Refresh the user list after update
                this.getStocks();
                // Clear the form after successful update
                this.resetForm();
            } catch (error) {
                //console.error('Error updating user:', error);
                throw error;
            }
        },
        resetForm() {
            // Reset form data to empty values
            this.form.name = '';
            this.form.location = '';
        },
        async deleteStock(id){
            const token = localStorage.getItem('token');
            try {
                await fetch(API_URL+`/stock/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                });
                this.getStocks();
                this.$notify({
                    message:
                    "Stock deleted",
                    icon: "delete_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'danger',
                });
            } catch (error) {
                //console.error('Error removing user:', error);
                throw error;
            }
        }
    }
};
</script>