<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Rapport</h4>
                        <p class="category">The global rapports and stats for products (Orders,Stocks)</p>
                    </md-card-header>
                    <md-card-content>
                        <div class="row col-lg-12 col-lg-md-6">
                            <div class="mb-3">
                                <label for="stockFilter" class="form-label">Filter by Stock</label>
                                <select v-model="selectedStock" id="stockFilter" class="form-select">
                                    <option value="">All Stocks</option>
                                    <option v-for="stock in stocks" :key="stock.id" :value="stock.id">{{ stock.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="nameFilter" class="form-label">Filter by Name</label>
                                <input v-model="nameFilter" id="nameFilter" class="form-control"
                                    placeholder="Enter product name/code" />
                            </div>
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Product ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Code</th>
                                        <th scope="col">Total Orders</th>
                                        <th scope="">Total Gain</th>
                                        <th scope="col">Total Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="product in paginatedProducts" :key="product.id"
                                        :class="rowClass(product)">
                                        <td>{{ product.id }}</td>
                                        <td>{{ product.name }}</td>
                                        <td>{{ product.code }}</td>
                                        <td>
                                            <ul>
                                                <li class="badge bg-dark">Global: <b>{{ product.nbrCommandes }}</b></li>
                                                <li class="badge bg-secondary">Waiting: <b>{{
                                    product.nbrCommandesWaiting }}</b></li>
                                                <li class="badge bg-primary">Confirmed: <b>{{
                                    product.nbrCommandesConfirmed }}</b></li>
                                                <li class="badge bg-warning">Livred: <b>{{ product.nbrCommandesLivred
                                                        }}</b></li>
                                                <li class="badge bg-success">Paid: <b>{{ product.nbrCommandesPaid }}</b>
                                                </li>
                                                <li class="badge bg-danger">Canceled: <b>{{ product.nbrCommandesCanceled
                                                        }}</b></li>
                                            </ul>
                                        </td>
                                        <td>{{ product.totalGain }} DH</td>
                                        <td>{{ displayQuantity(product) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" href="#"
                                            @click.prevent="changePage(currentPage - 1)">Previous</a>
                                    </li>
                                    <li class="page-item" v-for="page in totalPages" :key="page"
                                        :class="{ active: currentPage === page }">
                                        <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" href="#"
                                            @click.prevent="changePage(currentPage + 1)">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from '../../services/apis';

export default {
    data() {
        return {
            products: [],
            stocks: [],
            selectedStock: '',
            currentPage: 1,
            itemsPerPage: 20,
            nameFilter: ''
        };
    },
    computed: {
        filteredProducts() {
            let filtered = this.products;

            if (this.selectedStock) {
                filtered = filtered
                    .filter(product =>
                        product.stocks.some(stock => stock.id === parseInt(this.selectedStock))
                    )
                    .map(product => {
                        const stock = product.stocks.find(stock => stock.id === parseInt(this.selectedStock));
                        return {
                            ...product,
                            filteredQty: stock ? stock.pivot.qty : 0
                        };
                    });
            }

            if (this.nameFilter) {
                filtered = filtered.filter(
                    product =>
                        product.name.toLowerCase().includes(this.nameFilter.toLowerCase()) ||
                        product.code.toLowerCase().includes(this.nameFilter.toLowerCase())
                );
            }

            return filtered;
        },
        sortedProducts() {
            return [...this.filteredProducts].sort((a, b) => {
                const qtyA = this.selectedStock ? a.filteredQty : a.totalQty;
                const qtyB = this.selectedStock ? b.filteredQty : b.totalQty;
                return qtyA - qtyB;
            });
        },
        paginatedProducts() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.sortedProducts.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.sortedProducts.length / this.itemsPerPage);
        }
    },
    mounted() {
        this.fetchProducts();
    },
    methods: {
        async fetchProducts() {
            const tokenLc = localStorage.getItem('token');

            try {
                const response = await axios.get(API_URL + '/rapport/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`
                    }
                });
                this.products = response.data.data.products;
                this.stocks = response.data.data.stocks;
            } catch (error) {
                //console.error('Error fetching products:', error);
            }
        },
        displayQuantity(product) {
            return this.selectedStock ? product.filteredQty : product.totalQty;
        },
        rowClass(product) {
            const qty = this.selectedStock ? product.filteredQty : product.totalQty;
            if (qty <= 10) {
                return 'table-danger';
            } else if (qty > 10 && qty <= 20) {
                return 'table-warning';
            } else {
                return 'table-success';
            }
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        }
    }
};
</script>

<style scoped>
.container {
    max-width: 900px;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

.bg-success {
    background-color: #28a745 !important;
}

.text-white {
    color: #fff !important;
}

.text-dark {
    color: #343a40 !important;
}
</style>
