<template>

    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">ECOM Orders</h4>
                        <p class="category">List of ecom orders</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <div class=" input-group input-group-sm mb-3 text-center">
                                <span class="input-group-text"
                                    id="inputGroup-sizing-sm"><md-icon>search</md-icon></span>
                                <input type="text" v-model="searchQuery" placeholder="Search by ref" class="form-control">

                                <span class="input-group-text" id="inputGroup-sizing-sm">
                                    Filter by Status
                                </span>
                                <select v-model="selectedEtat" class="form-select">
                                    <option value="">All</option>
                                    <option value="0">WAITING</option>
                                    <option value="1">CONFIRMED</option>
                                    <option value="2">LIVRED</option>
                                    <option value="3">PAID</option>
                                    <option value="4">CANCELED</option>
                                </select>
                            </div>
                            <md-table v-model="paginatedOrders" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="REF">{{ item.ref }}</md-table-cell>
                                    <md-table-cell md-label="Price(MAD)">{{ item.prix_total }}</md-table-cell>
                                    <md-table-cell md-label="Client">{{ item.commandedetail?.name }}</md-table-cell>
                                    <md-table-cell md-label="Email">{{ item.commandedetail?.email }}</md-table-cell>
                                    <md-table-cell md-label="Phone">{{ item.commandedetail?.phone }}</md-table-cell>
                                    <md-table-cell md-label="Status">
                                        <span v-if="item.etat == 0"><i class="badge bg-secondary">WAITING</i></span>
                                        <span v-if="item.etat == 1"><i class="badge bg-primary">CONFIRMED</i></span>
                                        <span v-if="item.etat == 2"><i class="badge bg-warning">LIVRED</i></span>
                                        <span v-if="item.etat == 3"><i class="badge bg-success">PAID</i></span>
                                        <span v-if="item.etat == 4"><i class="badge bg-danger">CANCELED</i></span>
                                    </md-table-cell>
                                    <md-table-cell md-label="Date Order">{{ formattedDate(item.created_at)
                                        }}</md-table-cell>

                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-danger" @click="showEditOrderModal(item)"
                                            data-bs-toggle="modal" data-bs-target="#EditOrderModal">
                                            <md-icon>edit</md-icon>
                                        </md-button>
                                        <md-button class="md-primary" @click="showDetailOrderModal(item)"
                                            data-bs-toggle="modal" data-bs-target="#DetailProduitModal">
                                            <md-icon>list</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" href="#" @click.prevent="prevPage()">Previous</a>
                                    </li>
                                    <li class="page-item">
                                        <span>Page {{ currentPage }} of {{ totalPages }}</span>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" href="#" @click.prevent="nextPage()">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="modal fade" id="DetailProduitModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Order Details</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <p><strong>Reference:</strong> {{ orderDetails.ref }}</p>
                            <p><strong>Total Price:</strong> {{ orderDetails.prix_total }} <b>DHs</b></p>
                            <p><strong>Date:</strong> {{ formattedDate(orderDetails.created_at) }}</p>
                            <p><strong>Client:</strong> {{ orderDetails.commandedetail?.name }}</p>
                            <p><strong>Adresse:</strong> {{ orderDetails.commandedetail?.adresse }}
                                {{ orderDetails.commandedetail?.adrese_complete }}</p>
                            <p><strong>City:</strong> {{ orderDetails.commandedetail?.ville }}</p>
                            <p><strong>Phone:</strong> {{ orderDetails.commandedetail?.phone }}</p>
                            <p><strong>Email:</strong> {{ orderDetails.commandedetail?.email }}</p>
                            <h6 class="mt-4">Products of order :</h6>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Product Code</th>
                                        <th score="col">Qty</th>
                                        <th scope="col">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(produit, index) in orderDetails.produits" :key="index">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ produit.code }}</td>
                                        <td>{{ produit.pivot.qty }}</td>
                                        <td>{{ produit.prix }} DHS</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center">
                            <h1>Total Price: <b class="text-danger">{{ orderDetails.prix_total }}</b> DHs</h1>
                        </div>
                        <div class="card-footer text-center">
                            <button type="button" class="btn bg-danger"
                                v-if="orderDetails.etat == 0 || orderDetails.etat == 1"
                                @click="changeStateOrder(orderDetails.etat, orderDetails.id, 'cancel')">Cancel
                                Order</button>
                            <button type="button" class="btn bg-primary"
                                v-if="orderDetails.etat == 0 || orderDetails.etat == 4"
                                @click="changeStateOrder(orderDetails.etat, orderDetails.id, 'confirm')">Confirm
                                Order</button>
                            <button type="button" class="btn bg-success" v-if="orderDetails.etat == 2"
                                @click="changeStateOrder(orderDetails.etat, orderDetails.id, 'paid')">Order
                                Paid</button>
                            <button type="button" class="btn bg-info" v-if="orderDetails.etat == 1"
                                @click="changeStateOrder(orderDetails.etat, orderDetails.id, 'livred')">Order
                                Livred</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="EditOrderModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Order Details</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <p><strong>Reference:</strong> {{ orderToModify.ref }}</p>
                            <p><strong>Total Price:</strong> {{ orderToModify.prix_total }} <b>DHs</b></p>
                            <hr />
                            <h3>ADD PRODUCT TO ORDER</h3>
                            <form @submit.prevent="AddProductToOrder">
                                <span class="text-danger" v-show="newProductToOrder.prodId">Totale Qty :
                                    {{ newProductToOrder.prodId.totalQty }}</span>
                                <select class="form-select" v-model="newProductToOrder.prodId">
                                    <option v-for="prod in produits" :key="prod.id" :value="prod">{{ prod.name }}
                                    </option>
                                </select>
                                <input v-model="newProductToOrder.qty" type="number" min="1"
                                    :max="newProductToOrder.prodId.totalQty" name="qty"
                                    :disabled="!newProductToOrder.prodId || newProductToOrder.prodId.totalQty == 0" />
                                <button type="submit" class="btn btn-primary">+ Add product</button>
                            </form>

                            <span class="text-danger" v-show="newProductToOrder.prodId.totalQty == 0">
                                <div class="alert alert-warning" role="alert">
                                    You don't have products in stock : {{ newProductToOrder.prodId.totalQty }} You can't
                                    add the product!
                                </div>
                            </span>
                            <div v-if="newProductToOrder.prodId">
                                <span v-for="existingProducts in orderToModify.produits" :key="existingProducts.id">
                                    <span class="text-danger" v-if="existingProducts.id == newProductToOrder.prodId.id">
                                        <div class="alert alert-warning" role="alert">
                                            The product selected is aleardy in the order you cana manage the qty by the
                                            previous POP-UP.<br />
                                            if you add from there the same product the qty choosen will be added on the
                                            previous one :)
                                        </div>
                                    </span>
                                </span>
                            </div>
                            <hr />
                            <h6 class="mt-4">Products of order :</h6>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Product Code</th>
                                        <th score="col">Qty</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(produit, index) in orderToModify.produits" :key="index">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ produit.code }}</td>
                                        <td>
                                            {{ produit.pivot.qty }}


                                        </td>
                                        <td>{{ produit.prix }} DHS</td>
                                        <td>
                                            <button class="btn bg-warning"
                                                @click="showEditQtyProduitOrderModal(produit)">
                                                <md-icon>edit</md-icon>
                                            </button>
                                            <button @click="deleteProduitFromOrder(produit)"
                                                class="btn bg-danger"><md-icon>delete</md-icon></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="orderToModifyQty.id">
                            <h3>Modify Qty</h3>
                            <form @submit.prevent="changeQtyOrder(orderToModifyQty)">
                                <input type="number" name="qtyNew" v-model="orderToModifyQty.pivot.qty" />
                                <button type="submit" class="btn btn-primary">Modify Qty</button>
                            </form>

                            <hr />
                        </div>
                        <div class="text-center">
                            <h1>Total Price: <b class="text-danger">{{ orderToModify.prix_total }}</b> DHs</h1>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL } from '../../../services/apis';
import moment from 'moment';
export default {
    name: "OrdersEcomPage",
    data() {
        return {
            selected: [],
            orders: [],
            produits: [],
            orderDetails: [],
            orderToModify: [],
            orderToModifyQty: [],
            newProductToOrder: {
                prodId: '',
                orderId: '',
                qty: 1
            },
            searchQuery: '',
            currentPage: 1,
            itemsPerPage: 10,
            selectedEtat:'',
            dateOrderSorting: 'asc',
        };
    },
    created() {
        this.getOrders();
        this.getProduits();
    },
    computed: {
        filteredOrders() {
            const query = this.searchQuery.toLowerCase();
            return this.orders.filter(order => {
                const matchesSearch = order.ref.toLowerCase().includes(query);
                const matchesEtat = this.selectedEtat === '' || order.etat == this.selectedEtat;
                return matchesSearch && matchesEtat;
            });
        },
        paginatedOrders() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredOrders.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredOrders.length / this.itemsPerPage);
        }
    },
    methods: {
        async getOrders() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/orders/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    this.orders = data.data
                    this.orders.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching users:', error);
                throw error;
            }
        },
        async getProduits() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${API_URL}/produit/index`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (data.success) {

                    this.produits = data.data;
                    //console.log(this.produits);
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching products:', error);
                throw error;
            }
        },
        formattedDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        },
        showDetailOrderModal(item) {
            this.orderDetails = item
        },
        showEditOrderModal(item) {
            this.orderToModify = item
        },
        showEditQtyProduitOrderModal(item) {
            this.orderToModifyQty = item
        },
        async changeStateOrder(state, orId, type) {
            const tokenLc = localStorage.getItem('token');

            const orderData = {
                details: {
                    state: state,
                    orId: orId,
                    type: type
                }
            };
            try {
                const response = await fetch(API_URL + '/orders/modify/state', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                    body: JSON.stringify(orderData)
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error('Failed to place the order');
                } else {
                    //console.log(data.data)
                    //console.log(orderToDisplay.value)
                    this.orderDetails.etat = data.data.etat;
                    this.getOrders();
                    this.$notify({
                        message:
                            "State order changed",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'success',
                    });
                    /*notify({
                        message: `State changed`,
                        color: 'success',
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);*/

                }

            } catch (error) {
                //console.error('Error placing order:', error.message);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        async AddProductToOrder() {
            const tokenLc = localStorage.getItem('token');
            const tempData = {
                orderId: this.orderToModify.id,
                productId: this.newProductToOrder.prodId.id,
                qty: this.newProductToOrder.qty
            };
            const producrToAddForOrderData = {
                details: tempData
            };
            try {
                const response = await fetch(API_URL + '/orders/add/produit', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                    body: JSON.stringify(producrToAddForOrderData)
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error('Failed to place the order');
                } else {
                    this.orderToModify = data.data
                    this.newProductToOrder.prodId = ''
                    this.newProductToOrder.orderId = ''
                    this.newProductToOrder.qty = 1
                    /*= {
                        prodId: '',
                        orderId: '',
                        qty: 1
                    },*/
                }

            } catch (error) {
                //console.error('Error placing order:', error.message);
            }
        },
        async deleteProduitFromOrder(orderForDelete) {
            const tokenLc = localStorage.getItem('token');
            const orderModifyData = {
                details: orderForDelete
            };
            try {
                const response = await fetch(API_URL + '/orders/delete/produit', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                    body: JSON.stringify(orderModifyData)
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error('Failed to place the order');
                } else {
                    this.orderToModify.produits = orderToModify.produits.filter(item => item.id !== orderForDelete.id);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }

            } catch (error) {
                //console.error('Error placing order:', error.message);
            }
        },
        async changeQtyOrder(orderForModify) {
            const tokenLc = localStorage.getItem('token');
            const orderModifyData = {
                details: orderForModify
            };
            try {
                const response = await fetch(API_URL + '/orders/modify/qty', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                    body: JSON.stringify(orderModifyData)
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error('Failed to place the order');
                } else {
                    this.orderToModify = data.data;
                    /*setTimeout(() => {
                      window.location.reload();
                    }, 2000);*/
                }
            } catch (error) {
                //console.error('Error placing order:', error.message);
            }
        }
    }
};
</script>