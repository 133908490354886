<template>
  <form @submit.prevent="saveNewPassword">
    <md-card>
      <md-card-header :data-background-color="black">
        <h4 class="title">Edit Password</h4>
        <p class="category">Complete your profile</p>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">

          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>Old Password</label>
              <md-input v-model="form.oldPassword" type="password"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-33">
            <md-field>
              <label>New Password</label>
              <md-input v-model="form.newPassword" type="password"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button type="submit" class="md-raised md-black">Update Password</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { API_URL } from '../../services/apis';

export default {
  name: "edit-profile-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        id:-1,
        oldPassword: '',
        newPassword: ''
      },
      userInfos: JSON.parse(localStorage.getItem('userData'))
    };
  },
  methods: {
    async saveNewPassword() {
      const tokenLc = localStorage.getItem('token');
      try {
        const response = await fetch(API_URL + `/user/password/edit/${this.userInfos?.user?.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenLc}`,
          },
          body: JSON.stringify(this.form),
        });

        if (response.status === 200) {
          alert('DONE ! PASSWORD CHANGED')
          //init({ message: "You've successfully changed your password", color: 'success' })
          //emits('cancel')
          localStorage.setItem('isConnected', '0');
          location.reload();
        } else {
          alert('Failed to change the password !!')
          //init({ message: "Failed to change the password", color: 'danger' })
        }
      } catch (error) {
        alert('WRONG CURRENT PASSWORD !!')
        //init({ message: "Wrong current Password ! please verify", color: 'danger' })
      }
    }
  }
};
</script>
<style></style>
