<template>

    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Menus</h4>
                        <p class="category">List of menus website(ECOM)</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <md-table v-model="menus" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Name">{{ item.menu_name }}</md-table-cell>
                                    <md-table-cell md-label="Link">{{ item.path }}</md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-info" @click="editMenu(item)" data-bs-toggle="modal"
                                            data-bs-target="#EditMenukModal" title="EDIT Menu">
                                            <md-icon>edit</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="modal fade" id="EditMenukModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update Menu</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="updateMenu(form)">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" v-model="form.menu_name" required>
                                </div>
                                <div class="mb-3">
                                    <label for="link" class="form-label">Link</label>
                                    <input type="text" class="form-control" id="link" v-model="form.path" required>
                                </div>
                                <button type="submit" class="btn bg-black text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL } from '../../../services/apis';

export default {
    name: "MenusPage",
    data() {
        return {
            selected: [],
            menus: [],
            form: {
                id: -1,
                menu_name: '',
                path: ''
            }
        };
    },
    created() {
        this.getMenus();
    },
    methods: {
        async getMenus() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/settings/menus/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    //console.log(data);
                    this.menus = data.data;
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching users:', error);
                throw error;
            }
        },
        editMenu(menu) {
            // Populate the form fields with the selected menu's data
            this.form.id = menu.id;
            this.form.menu_name = menu.menu_name;
            this.form.path = menu.path;
            // Password should not be populated for security reasons
            // Optionally, you can choose to populate it if needed

        },
        async updateMenu(menu) {
            const token = localStorage.getItem('token');
            const { id, menu_name, path } = this.form;
            const updatedMenu = { id, menu_name, path };
            try {
                await fetch(API_URL + `/settings/menus/update/${menu.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(updatedMenu),
                });
                // Optionally, you can notify the user that the update was successful
                this.$notify({
                    message: "Menu Updated",
                    icon: "edit",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                // Refresh the user list after update
                this.getMenus();
                // Clear the form after successful update
                this.resetForm();
                document.getElementById('closeModal').click();
            } catch (error) {
                //console.error('Error updating user:', error);
                throw error;
            }
        },
        resetForm() {
            // Reset form data to empty values
            this.form.menu_name = '';
            this.form.path = '';
        },
    }
};
</script>