<template>

    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">POS Orders</h4>
                        <p class="category">List of pos orders</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <div class=" input-group input-group-sm mb-3 text-center">
                                <span class="input-group-text" id="inputGroup-sizing-sm"><md-icon>search</md-icon></span>
                                <input type="text" v-model="searchQuery" placeholder="Search by ref" class="form-control">
                                <span class="input-group-text" id="inputGroup-sizing-sm">
                                    Filter by Status
                                </span>
                                <select v-model="selectedEtat" class="form-select">
                                    <option value="">All</option>
                                    <option value="3">PAID</option>
                                    <option value="4">CANCELED</option>
                                </select>
                            </div>
                            
                            <md-table v-model="filteredOrders" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="REF">{{ item.ref }}</md-table-cell>
                                    <md-table-cell md-label="Price(MAD)">{{ item.prix_total }}</md-table-cell>
                                    <md-table-cell md-label="Vendor">{{ item.user.name }}</md-table-cell>
                                    <md-table-cell md-label="Stand">{{ item.stand.name }}</md-table-cell>
                                    <md-table-cell md-label="Etat">
                                        <span v-if="item.etat == 0"><i class="badge bg-secondary">WAITING</i></span>
                                        <span v-if="item.etat == 1"><i class="badge bg-primary">CONFIRMED</i></span>
                                        <span v-if="item.etat == 2"><i class="badge bg-warning">LIVRED</i></span>
                                        <span v-if="item.etat == 3"><i class="badge bg-success">PAID</i></span>
                                        <span v-if="item.etat == 4"><i class="badge bg-danger">CANCELED</i></span>
                                    </md-table-cell>
                                    <md-table-cell md-label="Date Order">{{ formattedDate(item.created_at)
                                        }}</md-table-cell>

                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-primary" @click="showDetailOrderModal(item)"
                                            data-bs-toggle="modal" data-bs-target="#DetailProduitModal">
                                            <md-icon>list</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="modal fade" id="DetailProduitModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Order Details</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <p><strong>Reference:</strong> {{ orderDetails.ref }}</p>
                            <p><strong>Total Price:</strong> {{ orderDetails.prix_total }} <b>DHs</b></p>
                            <p><strong>Stand:</strong> {{ orderDetails?.stand?.name }}</p>
                            <p><strong>Caissier:</strong> {{ orderDetails?.user?.name }}</p>
                            <p><strong>Date:</strong> {{ formattedDate(orderDetails.created_at) }}</p>

                            <h6 class="mt-4">Products of order :</h6>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Product Code</th>
                                        <th score="col">Qty</th>
                                        <th scope="col">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(produit, index) in orderDetails.produits" :key="index">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ produit.code }}</td>
                                        <td>{{ produit.pivot.qty }}</td>
                                        <td>{{ produit.prix }} DHS</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL } from '../../../services/apis';
import moment from 'moment';
export default {
    name: "OrdersPosPage",
    data() {
        return {
            selected: [],
            orders: [],
            orderDetails: [],
            searchQuery:'',
            selectedEtat:''
        };
    },
    created() {
        this.getOrders();
    },
    computed: {
        filteredOrders() {
            const query = this.searchQuery.toLowerCase();
            return this.orders.filter(order => {
                const matchesSearch = order.ref.toLowerCase().includes(query);
                const matchesEtat = this.selectedEtat === '' || order.etat == this.selectedEtat;
                return matchesSearch && matchesEtat;
            });
        },
    },
    methods: {
        async getOrders() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/commande/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    //console.log(data);
                    this.orders = data.data;
                    this.orders.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching users:', error);
                throw error;
            }
        },
        formattedDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        },
        showDetailOrderModal(item) {
            this.orderDetails = item
        }
    }
};
</script>