<template>
  <footer class="footer">
    <div class="text-center">
      
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href="https://lynxlook.com" target="_blank"
          >LYNX</a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
