<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Stats by Dates</h4>
                        <p class="category">Choose interval of dates to get preview of gain & total orders(POS&ECOM)</p>
                    </md-card-header>
                    <md-card-content>
                        <section class="d-flex flex-column gap-4">
                        <div class="row g-4">
                            <div class="col-12 col-sm-6 col-md-3">
                                <label class="text-dark font-bold">Start Date : </label>
                                <input type="date" v-model="startDate" class="form-control"
                                    placeholder="Select start date" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-3">
                                <label class="text-dark font-bold">End Date : </label>
                                <input type="date" v-model="endDate" class="form-control"
                                    placeholder="Select end date" />
                            </div>
                            <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                <VaButton @click="fetchStats" class="btn btn-outline-dark w-100">Filter</VaButton>
                            </div>
                        </div>
                        <div v-if="stats" class="row g-4">
                            <div class="col-12 col-md-6">
                                <div class="card text-white bg-dark h-100">
                                    <div class="card-body d-flex flex-column">
                                        <h4 class="card-title text-center flex-grow-1">Nombre of Orders</h4>
                                        <p class="card-text text-center text-danger flex-grow-1">{{
                                    stats.number_of_commands }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="card text-white bg-dark h-100">
                                    <div class="card-body d-flex flex-column">
                                        <h4 class="card-title text-center flex-grow-1">Total Gain</h4>
                                        <p class="card-text text-center text-success flex-grow-1">{{ stats.total_gain }}
                                            DHs</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </section>
                    </md-card-content>
                    
                </md-card>
            </div>
        </div>


    </div>
</template>

<script>
import axios from 'axios'
import { API_URL } from '../../services/apis'

export default {
    data() {
        return {
            startDate: '',
            endDate: '',
            stats: null,
        }
    },
    methods: {
        async getStats(start, end) {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/stat/global', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ startDate: start, endDate: end }),
                });
                const responseData = await response.json();
                if (!response.ok) {
                    throw new Error(responseData.message || 'Failed to save user');
                }
                this.stats = responseData.data;
            } catch (error) {
                //console.error('Error adding user:', error);
                throw error;
            }
        },
        fetchStats() {
            if (this.startDate) {
                this.getStats(this.startDate, this.endDate);
            } else {
                alert('Start date must be selected');
            }
        }
    },
}
</script>

<style scoped>
/* Add any required styles here */
</style>
