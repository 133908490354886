import { render, staticRenderFns } from "./ProduitsPage.vue?vue&type=template&id=de4bb904&scoped=true"
import script from "./ProduitsPage.vue?vue&type=script&lang=js"
export * from "./ProduitsPage.vue?vue&type=script&lang=js"
import style0 from "./ProduitsPage.vue?vue&type=style&index=0&id=de4bb904&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de4bb904",
  null
  
)

export default component.exports