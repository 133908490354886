<template>

    <div class="content">
        <div class="text-center">
            <md-button class="md-info" data-bs-toggle="modal" data-bs-target="#AddTagModal">Add
                Tag<md-icon>add</md-icon></md-button>
        </div>
        <div class="modal fade" id="AddTagModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Tag</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="saveAddTag">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" v-model="form.name" required>
                                </div>
                                
                                <button type="submit" class="btn bg-dark text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Tags</h4>
                        <p class="category">List of tags</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <md-table v-model="tags" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-info" @click="editTag(item)" data-bs-toggle="modal" data-bs-target="#EditTagkModal" title="EDIT TAG">
                                            <md-icon>edit</md-icon>
                                        </md-button>
                                        <md-button class="md-danger" @click="deleteTag(item.id)" >
                                            <md-icon>delete</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="modal fade" id="EditTagkModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update Tag</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="updateTag">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" v-model="form.name" required>
                                </div>
                                <button type="submit" class="btn bg-black text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL } from '../../services/apis';

export default {
    name: "TagsPage",
    data() {
        return {
            selected: [],
            tags: [],
            form: {
                name: ''
            }
        };
    },
    created() {
        this.getTags();
    },
    methods: {
        async getTags() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/tag/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    this.tags = data.data
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
             //   console.error('Error fetching users:', error);
                throw error;
            }
        },
        async saveAddTag() {
            const tokenLc = localStorage.getItem('token');
            const { name } = this.form; // Destructure form data
            const tag = { name }; // Create user object
            try {
                const response = await fetch(API_URL + '/tag/store', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                    body: JSON.stringify(tag),
                });
                const responseData = await response.json();
                if (!response.ok) {
                    throw new Error(responseData.message || 'Failed to save user');
                }
                this.resetForm();
                this.getTags();
                this.$notify({
                    message:
                    "Tag Added",
                    icon: "add_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                document.getElementById('closeModal').click();
            } catch (error) {
               // console.error('Error adding user:', error);
                throw error;
            }
        },
        editTag(tag) {
            // Populate the form fields with the selected tag's data
            this.form.id = tag.id;
            this.form.name = tag.name;
            // Password should not be populated for security reasons
            // Optionally, you can choose to populate it if needed
            
        },
        async updateTag() {
            const token = localStorage.getItem('token');
            const { id, name } = this.form;
            const updatedTag = { id, name };
            try {
                await fetch(API_URL + `/tag/update/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(updatedTag),
                });
                // Optionally, you can notify the user that the update was successful
                this.$notify({
                    message: "Tag Updated",
                    icon: "edit",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                // Refresh the user list after update
                this.getTags();
                // Clear the form after successful update
                this.resetForm();
            } catch (error) {
                //console.error('Error updating user:', error);
                throw error;
            }
        },
        resetForm() {
            // Reset form data to empty values
            this.form.name = '';
        },
        async deleteTag(id){
            const token = localStorage.getItem('token');
            try {
                await fetch(API_URL+`/tag/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                });
                this.getTags();
                this.$notify({
                    message:
                    "Tag deleted",
                    icon: "delete_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'danger',
                });
            } catch (error) {
                //console.error('Error removing user:', error);
                throw error;
            }
        }
    }
};
</script>