<template>
  <div class="content">
   
    <div class="md-layout">

      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="green">
          <template slot="header">
            <md-icon>person</md-icon>
          </template>

          <template slot="content">
            <p class="category">Nombre of Employees</p>
            <h3 class="title">{{primaryStats.nbrOfEmployees}}</h3>
          </template>

          
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="orange">
          <template slot="header">
            <md-icon>content_copy</md-icon>
          </template>

          <template slot="content">
            <p class="category">Nombre of Stands</p>
            <h3 class="title">
              {{ primaryStats?.nbrOfStands }}
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="red">
          <template slot="header">
            <md-icon>info_outline</md-icon>
          </template>

          <template slot="content">
            <p class="category">Nombre of Stocks</p>
            <h3 class="title">{{ primaryStats?.nbrOfStocks }}</h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="blue">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>

          <template slot="content">
            <p class="category">Total Qty</p>
            <h3 class="title">{{ primaryStats?.totalQty }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="green">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>

          <template slot="content">
            <p class="category">Nombre of Products</p>
            <h3 class="title">{{ primaryStats?.nbrOfProducts }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="orange">
          <template slot="header">
            <md-icon>content_copy</md-icon>
          </template>

          <template slot="content">
            <p class="category">Nombre of Categories</p>
            <h3 class="title">
              {{ primaryStats?.nbrOfCategories }}
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="red">
          <template slot="header">
            <md-icon>info_outline</md-icon>
          </template>

          <template slot="content">
            <p class="category">Nombre of Tags</p>
            <h3 class="title">{{ primaryStats?.nbrOfTags }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="blue">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>

          <template slot="content">
            <p class="category">Orders POS</p>
            <h3 class="title">{{ primaryStats?.nbrOfOrders }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="green">
          <template slot="header">
            <md-icon>money</md-icon>
          </template>

          <template slot="content">
            <p class="category">Total Gain POS</p>
            <h3 class="title">DH {{ primaryStats?.gainTotal }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="orange">
          <template slot="header">
            <md-icon>money</md-icon>
          </template>

          <template slot="content">
            <p class="category">Today Gain POS</p>
            <h3 class="title">
              DH {{ primaryStats?.gainToday }}
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="red">
          <template slot="header">
            <md-icon>money</md-icon>
          </template>

          <template slot="content">
            <p class="category">This Week Gain POS</p>
            <h3 class="title">DH {{ primaryStats?.gainWeek }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="blue">
          <template slot="header">
            <md-icon>money</md-icon>
          </template>

          <template slot="content">
            <p class="category">This Month Gain POS</p>
            <h3 class="title">DH {{ primaryStats?.gainMonth }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="green">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>

          <template slot="content">
            <p class="category">Orders ECOM</p>
            <h3 class="title">{{ primaryStats?.nbrGlobalOfOrdersECOM }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="orange">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>

          <template slot="content">
            <p class="category">Orders WAITING</p>
            <h3 class="title">
              {{ primaryStats?.nbrOfOrdersWaitingECOM }}
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="red">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>

          <template slot="content">
            <p class="category">Orders CONFIRMED</p>
            <h3 class="title">{{ primaryStats?.nbrOfOrdersConfirmedECOM }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="blue">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>

          <template slot="content">
            <p class="category">Orders LIVRED</p>
            <h3 class="title">{{ primaryStats?.nbrOfOrdersLivredECOM }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="green">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>

          <template slot="content">
            <p class="category">Orders PAID</p>
            <h3 class="title">{{ primaryStats?.nbrOfOrdersPaidECOM }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="orange">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>

          <template slot="content">
            <p class="category">Orders CANCELED</p>
            <h3 class="title">
              {{ primaryStats?.nbrOfOrdersCanceledECOM }}
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="red">
          <template slot="header">
            <md-icon>money</md-icon>
          </template>

          <template slot="content">
            <p class="category">Total Gain ECOM</p>
            <h3 class="title">DH {{ primaryStats?.gainTotalECOM }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="blue">
          <template slot="header">
            <md-icon>money</md-icon>
          </template>

          <template slot="content">
            <p class="category">Today Gain ECOM</p>
            <h3 class="title">DH {{ primaryStats?.gainTodayECOM }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="green">
          <template slot="header">
            <md-icon>money</md-icon>
          </template>

          <template slot="content">
            <p class="category">Week Gain ECOM</p>
            <h3 class="title">DH {{ primaryStats?.gainWeekECOM }}</h3>
          </template>

        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="orange">
          <template slot="header">
            <md-icon>money</md-icon>
          </template>

          <template slot="content">
            <p class="category">Month Gain ECOM</p>
            <h3 class="title">
              DH {{ primaryStats?.gainMonthECOM }}
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">

      </div>
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
        <md-card>
          <md-card-header data-background-color="orange">
            <h4 class="title">POS Orders</h4>
            <p class="category">The last 10 orders from POS</p>
          </md-card-header>
          <md-card-content>
            <md-table v-model="primaryStats.lastTenCommandes" :table-header-color="orange">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID">{{ item.ref }}</md-table-cell>
                <md-table-cell md-label="Price(MAD)">{{ item.prix_total }}</md-table-cell>
                <md-table-cell md-label="Vendor">{{ item.user.name }}</md-table-cell>
                <md-table-cell md-label="Stand">{{ item.stand.name }}</md-table-cell>
                <md-table-cell md-label="Date">{{ formattedDate(item.created_at) }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
        <md-card>
          <md-card-header data-background-color="orange">
            <h4 class="title">ECOM Orders</h4>
            <p class="category">The last 10 orders from ECOM</p>
          </md-card-header>
          <md-card-content>
            <md-table v-model="primaryStats.lastTenCommandesECOM" :table-header-color="orange">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID">{{ item.ref }}</md-table-cell>
                <md-table-cell md-label="Price(MAD)">{{ item.prix_total }}</md-table-cell>
                <md-table-cell md-label="State">
                  <span v-if="item.etat == 0"><i class="badge bg-secondary">WAITING</i></span>
                  <span v-if="item.etat == 1"><i class="badge bg-primary">CONFIRMED</i></span>
                  <span v-if="item.etat == 2"><i class="badge bg-warning">LIVRED</i></span>
                  <span v-if="item.etat == 3"><i class="badge bg-success">PAID</i></span>
                  <span v-if="item.etat == 4"><i class="badge bg-danger">CANCELED</i></span>
                </md-table-cell>
                <md-table-cell md-label="Client">{{ item.commandedetail.name }}</md-table-cell>
                <md-table-cell md-label="Phone">{{ item.commandedetail.phone }}</md-table-cell>
                <md-table-cell md-label="Date">{{ formattedDate(item.created_at) }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  StatsCard,
} from "@/components";
import { API_URL } from "../services/apis";
import moment from 'moment';

export default {
  components: {
    StatsCard,
  },
  data() {
    return {
      primaryStats:[]
    };
  },
  created() {
    this.getStats();
  },
  methods: {
    async getStats() {
      const tokenLc = localStorage.getItem('token');
      try {
        const response = await fetch(API_URL + '/stat/dashboard/index', {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${tokenLc}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data.success) {
          //console.log(data);
          this.primaryStats = data.data
        } else {
          throw new Error(data.message);
        }
      } catch (error) {
        //console.error('Error fetching users:', error);
        throw error;
      }
    },
    formattedDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },
  }
};
</script>
