<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Stats by Users</h4>
                        <p class="category">Choose Client or Vendor get gain & total orders(POS&ECOM)</p>
                    </md-card-header>
                    <md-card-content>
                        <section class="flex flex-col gap-4">
                            <label class="text-dark font-bold">Vendors : </label>
                            <select v-model="userSelected" class="form-select" aria-label="Select User">

                                <option :value="user.id" v-for="user in Users" :key="user.id">{{ user.name }}</option>
                            </select>
                            <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
                                <button @click="getStatUser" class="btn btn-outline-dark w-100 ">Filter</button>
                            </div>

                            <div v-if="stats">
                                <div class="card w-full">
                                    <div class="card-header d-flex justify-content-between">
                                        <h1 class="card-title text-secondary font-bold text-uppercase">{{
                                stats.user.commandes.length }}
                                            Commandes</h1>
                                    </div>
                                    <div class="card-body">
                                        <div v-if="stats.user.commandes.length > 0">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Ref</th>
                                                        <th>Prix Total</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="commande in stats.user.commandes" :key="commande.id">
                                                        <td>{{ commande.ref }}</td>
                                                        <td>{{ commande.prix_total }} DHs</td>
                                                        <td>{{ formatDate(commande.created_at) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-else class="p-4 text-center text-secondary">No Commandes</div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between gap-2 items-center py-2">
                                    <div>
                                        <b>{{ stats.user.commandes.length }} results.</b>
                                    </div>
                                </div>
                                Total Gain : {{ stats.userGain }} DHs
                            </div>
                        </section>
                    </md-card-content>
                </md-card>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from '../../services/apis';

export default {
    data() {
        return {
            stats: null,
            userSelected: null,
            Users: [],
        };
    },
    mounted() {
        this.getUsers();
    },
    methods: {
        async getStatUser() {
            try {
                const response = await axios.get(API_URL + `/stat/user/${this.userSelected}`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.data.success) {
                    this.stats = response.data.data;
                } else {
                    throw new Error(response.data.message);
                }
            } catch (error) {
                //console.error('Error fetching users:', error);
                throw error;
            }
        },
        async getUsers() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/user/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    this.Users = data.data;
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching users:', error);
                throw error;
            }
        },
        formatDate(dateString) {
            return new Date(dateString).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        }
    }
};
</script>

<style scoped>
/* Add your styles here */
</style>