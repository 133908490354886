<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link>
      <sidebar-link to="/users">
        <md-icon>person</md-icon>
        <p>Users</p>
      </sidebar-link>
      
      <sidebar-link to="/stands">
        <md-icon>store</md-icon>
        <p>Stands</p>
      </sidebar-link>
      <sidebar-link to="/stocks">
        <md-icon>warehouse</md-icon>
        <p>Warehouses</p>
      </sidebar-link>
      <sidebar-link to="/categories">
        <md-icon>category</md-icon>
        <p>Categories</p>
      </sidebar-link>
      <sidebar-link to="/tags">
        <md-icon>bookmark</md-icon>
        <p>Tags</p>
      </sidebar-link>
      <sidebar-link to="/products">
        <md-icon>inventory</md-icon>
        <p>Products</p>
      </sidebar-link>
      <hr/>
      <sidebar-link to="/orders">
        <md-icon>money</md-icon>
        <p>POS Orders</p>
      </sidebar-link>
      <sidebar-link to="/orders/ecom">
        <md-icon>money</md-icon>
        <p>ECOM Orders</p>
      </sidebar-link>
      <sidebar-link to="/coupons">
        <md-icon>discount</md-icon>
        <p>Coupons</p>
      </sidebar-link>
      <hr/>

      <sidebar-link to="/stats">
        <md-icon>analytics</md-icon>
        <p>Stats</p>
      </sidebar-link>
      <sidebar-link to="/rapports">
        <md-icon>leaderboard</md-icon>
        <p>Rapports</p>
      </sidebar-link>
      <hr/>
      <sidebar-link to="/mailings">
        <md-icon>mail</md-icon>
        <p>Mailing</p>
      </sidebar-link>
      <hr/>
      <sidebar-link to="/settings/menus">
        <md-icon>list</md-icon>
        <p>Menus</p>
      </sidebar-link>
      <sidebar-link to="/settings/infos">
        <md-icon>store</md-icon>
        <p>Store</p>
      </sidebar-link>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    FixedPlugin
  },
  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
    };
  },
  methods:{
    Logout(){
      localStorage.setItem('isConnected','0');
      localStorage.setItem('userData','null');
      localStorage.setItem('token','null');
      window.location.reload();
    }
  }
};
</script>
