<template>
    <div class="content">
        <div class="text-center">
            <md-button class="md-info" data-bs-toggle="modal" data-bs-target="#AddProductModal">
                Add Product<md-icon>add</md-icon>
            </md-button>
        </div>
        <div class="modal fade" id="AddProductModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Product</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="saveAddProduit">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" v-model="form.name" required>
                                </div>
                                <div class="mb-3">
                                    <label for="code" class="form-label">Code</label>
                                    <input type="text" class="form-control" id="code" v-model="form.code" required>
                                </div>
                                <div class="mb-3">
                                    <label for="ugs" class="form-label">UGS</label>
                                    <input type="text" class="form-control" id="ugs" v-model="form.ugs" required>
                                </div>
                                <div class="mb-3">
                                    <label for="description" class="form-label">Short Description</label>
                                    <ckeditor class="ckedito-perso" label="Description" v-model="form.short_description"
                                        :editor="editor" :config="editorConfig"></ckeditor>
                                </div>
                                <div class="mb-3">
                                    <label for="description" class="form-label">Description</label>
                                    <ckeditor class="ckedito-perso" label="Description" v-model="form.description"
                                        :editor="editor" :config="editorConfig"></ckeditor>
                                </div>
                                <div class="mb-3">
                                    <label for="prix_fournisseur" class="form-label">Cost</label>
                                    <input type="number" class="form-control" id="prix_fournisseur"
                                        v-model="form.prix_fournisseur" required>
                                </div>
                                <div class="mb-3">
                                    <label for="prix_old" class="form-label">Old price</label>
                                    <input type="number" class="form-control" id="prix_old" v-model="form.prix_old"
                                        required>
                                </div>
                                <div class="mb-3">
                                    <label for="prix" class="form-label">Price</label>
                                    <input type="number" class="form-control" id="prix" v-model="form.prix" required>
                                </div>
                                <div class="mb-3">
                                    <label for="meta_title" class="form-label">Meta Title</label>
                                    <input type="text" class="form-control" id="meta_title" v-model="form.meta_titre"
                                        required>
                                </div>
                                <div class="mb-3">
                                    <label for="meta_description" class="form-label">Meta Description</label>
                                    <textarea type="text" class="form-control" id="meta_description"
                                        v-model="form.meta_description" required></textarea>
                                </div>
                                <div class="mb-3">
                                    <label for="Stock" class="form-label">Stock</label>
                                    <select class="form-select" v-model="form.stock">
                                        <option selected value="" disabled>SELECT STOCK</option>
                                        <option v-for="stock in stocks" :key="stock.id" :value="stock.id">{{ stock.name
                                            }}</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="Qty" class="form-label">Qty</label>
                                    <input type="number" min="0" class="form-control" id="qty" v-model="form.qty"
                                        required>
                                </div>
                                <button type="submit" class="btn bg-dark text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Products</h4>
                        <p class="category">List of products</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <div class=" input-group input-group-sm mb-3 text-center">
                                <span class="input-group-text" id="inputGroup-sizing-sm"><md-icon>search</md-icon></span>
                                <input type="text" v-model="searchQuery" placeholder="Search by code or name" class="form-control">
                            </div>
                            <md-table v-model="paginatedProduits" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
                                    <md-table-cell md-label="Code">{{ item.code }}</md-table-cell>
                                    <md-table-cell md-label="Image"><img
                                            :src="storageUrl+'/' + item.picture"
                                            style="width:100px;height:100px;" /></md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <router-link :to="`/products/${item.id}`">
                                            <md-button class="md-primary">
                                                <md-icon>edit</md-icon>
                                            </md-button>
                                        </router-link>
                                        <md-button class="md-warning" @click="showStockProduitModal(item)"
                                            data-bs-toggle="modal" data-bs-target="#StockProduitModal"
                                            title="Modify Stocks">
                                            <md-icon>check</md-icon>
                                        </md-button>
                                        <md-button class="md-danger" @click="deleteProduit(item.id)">
                                            <md-icon>delete</md-icon>
                                        </md-button>
                                        <md-button class="md-warning" @click="historyStockProduit(item)"
                                            data-bs-toggle="modal" data-bs-target="#HistoryStockProduitModal"
                                            title="History Product in Stocks">
                                            <md-icon>history</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" href="#" @click.prevent="prevPage()">Previous</a>
                                    </li>
                                    <li class="page-item">
                                        <span>Page {{ currentPage }} of {{ totalPages }}</span>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" href="#" @click.prevent="nextPage()">Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="modal fade" id="StockProduitModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Modify Stock</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="saveStockToProduit(produitStock.id)">
                                <select  v-model="formStock.stock_id">   
                                    <option selected disabled value="">Choose Stock</option>
                                    <option v-for="stock in stocks" :key="stock.id" :value="stock.id">{{ stock.name }}
                                    </option>
                                </select>
                                <input type="number" min="0" placeholder="Qty" v-model="formStock.qty"/>
                                <button type="submit" class="btn btn-dark">Submit</button>
                            </form>
                            <md-table v-model="produitStock.stocks" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Name">{{ item.name }} - {{ item.id }}</md-table-cell>
                                    <md-table-cell md-label="Qty">
                                        <form @submit.prevent="saveNewStock(item.pivot.produit_id)">
                                            <input v-model="item.pivot.qty" label="Quantity" type="number" name="qty" />
                                            <button type="submit" class="btn btn-dark">Submit</button>
                                        </form>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="HistoryStockProduitModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">History Product in Stocks</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <md-table v-model="histoStockProduit.histoproduitstocks" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="OldQty">{{ item.old_stock }}</md-table-cell>
                                    <md-table-cell md-label="NewQty">
                                        {{ item.new_stock }}
                                    </md-table-cell>
                                    <md-table-cell md-label="Date">{{ formattedDate(item.created_at) }}</md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL,STORAGE_URL } from '../../services/apis';
import Editor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';

export default {
    name: 'ProduitsPage',
    data() {
        return {
            storageUrl:STORAGE_URL,
            produits: [],
            tags: [],
            categories: [],
            stocks: [],
            editor: Editor,
            editorData: '<p>Hello YOUR TEXT HERE</p>',
            editorConfig: {
                // CKEditor configuration options
            },
            form: {
                name: '',
                code: '',
                ugs: '',
                description: 'Fill the description',
                short_description: 'Fill the short description',
                prix_fournisseur: 0,
                prix_old: 0,
                prix: 0,
                meta_titre: '',
                meta_description: 'Text',
                categorie: [],
                tag: [],
                stock: '',
                qty: 0
            },
            produitStock: [],
            stockToModify: [],
            formStock:{
                stock_id:0,
                qty:0,
            },
            searchQuery: '',
            histoStockProduit:[],
            currentPage: 1,
            itemsPerPage: 10,
        };
    },
    created() {
        this.getProduits();
        this.getCategories();
        this.getTags();
        this.getStocks();
    },
    computed: {
        filteredProduits() {
            const query = this.searchQuery.toLowerCase();
            return this.produits.filter(produit =>
                produit.name.toLowerCase().includes(query) ||
                produit.code.toLowerCase().includes(query)
            );
        },
        paginatedProduits() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredProduits.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredProduits.length / this.itemsPerPage);
        }
    },
    methods: {
        async getProduits() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${API_URL}/produit/index`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (data.success) {
                    this.produits = data.data;
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching products:', error);
                throw error;
            }
        },
        async getCategories() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${API_URL}/categorie/index`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (data.success) {
                    this.categories = data.data;
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching categories:', error);
                throw error;
            }
        },
        async getTags() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${API_URL}/tag/index`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (data.success) {
                    this.tags = data.data;
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching tags:', error);
                throw error;
            }
        },
        async getStocks() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${API_URL}/stock/index`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (data.success) {
                    this.stocks = data.data;
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching stocks:', error);
                throw error;
            }
        },
        async saveAddProduit() {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${API_URL}/produit/store`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(this.form)
                });
                if (!response.ok) {
                    const responseData = await response.json();
                    throw new Error(responseData.message || 'Failed to save product');
                }
                this.resetForm();
                this.getProduits();
                this.$notify({
                    message:
                        "Produit Added",
                    icon: "add_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                document.getElementById('closeModal').click();
            } catch (error) {
                //console.error('Error adding product:', error);
                throw error;
            }
        },
        resetForm() {
            this.form = {
                code: '',
                name: '',
                description: '',
                short_description: '',
                prix_fournisseur: '',
                prix_old: '',
                prix: '',
                categorie: [],
                tag: [],
                stock: '',
                qty: 0
            };
        },
        async deleteProduit(id) {
            const token = localStorage.getItem('token');
            try {
                await fetch(API_URL + `/produit/delete/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                this.getProduits();
            } catch (error) {
                //console.error('Error removing stand:', error);
                throw error;
            }
        },
        showStockProduitModal(stockP) {
            this.produitStock = stockP
            this.stockToModify = stockP
        },
        showEditStockProduitModal(stockTm) {
            this.stockToModify = stockTm
        },
        async saveNewStock(prodId) {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/stock/modify/${prodId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(this.stockToModify),
                });
                if (response.ok) {
                    this.$notify({
                        message:
                            "Stock Modified",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'success',
                    });
                    setTimeout(function () { window.location.reload() }.bind(this), 1000)

                } else if (response.status === 409) {
                    this.$notify({
                        message:
                            "Stock Modified FAILED",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger',
                    });
                } else {
                    this.$notify({
                        message:
                            "Stock Modified FAILED",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger',
                    });
                }
            } catch (error) {
                //console.error('Error attaching category:', error);
                throw error;
            }
        },
        async saveStockToProduit(idProd) {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/produit/stock/add/${idProd}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(this.formStock),
                });

                if (response.ok) {
                    this.$notify({
                        message:
                            "Stock Added",
                        icon: "add_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'success',
                    });
                    location.reload();
                } else if (response.status === 409) {
                    this.$notify({
                        message:
                            "Stock Attached aleardy",
                        icon: "add_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'warning',
                    });
                } else {
                    this.$notify({
                        message:
                            "Stock attached FAILED",
                        icon: "add_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger',
                    });
                }
            } catch (error) {
                //console.error('Error attaching category:', error);
                throw error;
            }
        },
        historyStockProduit(produit){
            this.histoStockProduit = produit
        },
        formattedDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
    }
};
</script>

<style scoped>
@import 'vue-multiselect/dist/vue-multiselect.min.css';
</style>