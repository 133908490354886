<template>

    <div class="content">
        <div class="text-center">
            <md-button class="md-info" data-bs-toggle="modal" data-bs-target="#AddUserModal">Add
                User<md-icon>add</md-icon></md-button>
        </div>
        <div class="modal fade" id="AddUserModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add User</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="saveAddUser">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" v-model="form.name" required>
                                </div>
                                <div class="mb-3">
                                    <label for="role" class="form-label">Role</label>
                                    <select class="form-select" id="role" v-model="form.role" required>
                                        <option value="" disabled>Select Role</option>
                                        <option value="3">MANAGER</option>
                                        <option value="2">VENDOR</option>
                                        <option value="1">CLIENT</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="email" v-model="form.email" required>
                                </div>
                                <div class="mb-3">
                                    <label for="password" class="form-label">Password</label>
                                    <input type="password" class="form-control" id="password" v-model="form.password"
                                        required>
                                </div>
                                <button type="submit" class="btn bg-black text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Users</h4>
                        <p class="category">List of users</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <div class=" input-group input-group-sm mb-3 text-center">
                                <span class="input-group-text" id="inputGroup-sizing-sm"><md-icon>search</md-icon></span>
                                <input type="text" v-model="searchQuery" placeholder="Search by name or email" class="form-control">
                            </div>
                            
                            <md-table v-model="filteredUsers" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
                                    <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
                                    <md-table-cell md-label="Role">
                                        <span v-if="item.role == 2"><i class="badge bg-warning">VENDOR</i></span>
                                        <span v-if="item.role == 3"><i class="badge bg-danger">MANAGER</i></span>
                                        <span v-if="item.role == 1"><i class="badge bg-info">CLIENT</i></span>
                                    </md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-info" @click="editUser(item)" data-bs-toggle="modal"
                                            data-bs-target="#EditUserModal" title="EDIT USER">
                                            <md-icon>edit</md-icon>
                                        </md-button>
                                        <md-button class="md-warning" @click="standUser(item)" data-bs-toggle="modal"
                                            data-bs-target="#standUserModal"
                                            title="STAND USER" v-if="item.role == 2"><md-icon>store</md-icon></md-button>
                                        <md-button class="md-success" data-bs-toggle="modal" @click="editnewPassword(item.id)"
                                            data-bs-target="#passwordUserModal"
                                            title="MODIFY PASSWORD"><md-icon>lock</md-icon></md-button>
                                        <md-button class="md-danger" @click="deleteUser(item.id)"
                                            title="DELETE USER"><md-icon>delete</md-icon></md-button>
                                        <md-button class="md-primary" @click="historiqueUser(item)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#HistoUserModal"
                                            title="HISTORY USER"><md-icon>history</md-icon></md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="modal fade" id="EditUserModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add User</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="updateUser">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="name" v-model="form.name" required>
                                </div>
                                <div class="mb-3">
                                    <label for="role" class="form-label">Role</label>
                                    <select class="form-select" id="role" v-model="form.role" required>
                                        <option value="" disabled>Select Role</option>
                                        <option value="3">MANAGER</option>
                                        <option value="2">VENDOR</option>
                                        <option value="1">CLIENT</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="email" v-model="form.email" required>
                                </div>
                                <!--<div class="mb-3">
                                    <label for="password" class="form-label">Password</label>
                                    <input type="password" class="form-control" id="password" v-model="form.password"
                                        required>
                                </div>-->
                                <button type="submit" class="btn bg-black text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="standUserModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">User stands</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="saveStandToUser">
                                <select class="form-select" v-model="selectedStandId">
                                    <option selected value="" disabled>Choose Stand</option>
                                    <option v-for="stand in stands" :key="stand.id" :value="stand.id">{{ stand.name }}
                                    </option>
                                </select>
                                <button type="submit" class="btn bg-black text-white w-100">Add stand</button>
                            </form>
                            <md-table v-model="userStand.stands" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Name">{{ item.name }} - {{ item.id }}</md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-danger" @click="deleteStand(item)"
                                            title="DELETE STAND"><md-icon>delete</md-icon></md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="HistoUserModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">History Connectivity User</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <md-table v-model="histoUser.histoconnectiviteusers" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
                                    <md-table-cell md-label="Date">{{ formattedDate(item.created_at) }}</md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="passwordUserModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Modify password</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="saveNewPassword">
                                <input type="password" name="password" v-model="newPassword" />
                                <button class="btn bg-dark text-white" type="submit">Save</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL } from '../../services/apis';
import moment from 'moment';

export default {
    name: "UsersPage",
    data() {
        return {
            selected: [],
            users: [],
            stands: [],
            userStand: [],
            selectedStandId:0,
            form: {
                name: '',
                role: '',
                email: '',
                password: ''
            },
            searchQuery: '',
            histoUser:[],
            newPassword:'',
            userID:0
        };
    },
    created() {
        this.getUsers();
        this.getStands();
    },
    computed: {
        filteredUsers() {
            const query = this.searchQuery.toLowerCase();
            return this.users.filter(user =>
                user.name.toLowerCase().includes(query) ||
                user.email.toLowerCase().includes(query)
            );
        }
    },
    methods: {
        async getUsers() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/user/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    this.users = data.data
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching users:', error);
                throw error;
            }
        },
        async saveAddUser() {
            const tokenLc = localStorage.getItem('token');
            const { name, role, email, password } = this.form; // Destructure form data
            const user = { name, role, email, password }; // Create user object
            try {
                const response = await fetch(API_URL + '/user/store', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                    body: JSON.stringify(user),
                });
                const responseData = await response.json();
                if (!response.ok) {
                    throw new Error(responseData.message || 'Failed to save user');
                }
                this.resetForm();
                this.getUsers();
                this.$notify({
                    message:
                        "User Added",
                    icon: "add_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                document.getElementById('closeModal').click();
            } catch (error) {
                //console.error('Error adding user:', error);
                throw error;
            }
        },
        editUser(user) {
            // Populate the form fields with the selected user's data
            this.form.id = user.id;
            this.form.name = user.name;
            this.form.role = user.role;
            this.form.email = user.email;
            // Password should not be populated for security reasons
            // Optionally, you can choose to populate it if needed

        },
        async updateUser() {
            const token = localStorage.getItem('token');
            const { id, name, role, email } = this.form;
            const updatedUser = { id, name, role, email };
            try {
                await fetch(API_URL + `/user/update/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(updatedUser),
                });
                // Optionally, you can notify the user that the update was successful
                this.$notify({
                    message: "User Updated",
                    icon: "edit",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                // Refresh the user list after update
                this.getUsers();
                // Clear the form after successful update
                this.resetForm();
            } catch (error) {
                //console.error('Error updating user:', error);
                throw error;
            }
        },
        resetForm() {
            // Reset form data to empty values
            this.form.name = '';
            this.form.role = '';
            this.form.email = '';
            this.form.password = '';
        },
        async deleteUser(id) {
            const token = localStorage.getItem('token');
            try {
                await fetch(API_URL + `/user/delete/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                this.getUsers();
                this.$notify({
                    message:
                        "User deleted",
                    icon: "delete_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'danger',
                });
            } catch (error) {
                //console.error('Error removing user:', error);
                throw error;
            }
        },
        standUser(standU) {
            this.getStands()
            this.userStand = standU
        },
        async getStands() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/stand/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    this.stands = data.data
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                // console.error('Error fetching users:', error);
                throw error;
            }
        },
        async deleteStand(stand) {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/user/stand/delete/${stand.pivot.user_id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(stand.pivot),
                });
                if (response.ok) {
                    const index = this.userStand.stands.findIndex(item => item.id === stand.id);
                    // Remove the stand from the array
                    this.userStand.stands.splice(index, 1);
                    //console.log('Stand deleted successfully');
                    this.$notify({
                        message:
                            "Stand Deleted",
                        icon: "delete_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger',
                    });
                } else if (response.status === 409) {
                    // console.error('Stand already deleted');

                    this.$notify({
                        message:
                            "Stand Aleardy Deleted",
                        icon: "delete_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'warning',
                    });
                    // Handle conflict, maybe display error message
                } else {
                    //console.error('Failed to delete stand');

                    this.$notify({
                        message:
                            "Stand Delete Failed!",
                        icon: "delete_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger',
                    });
                    // Handle other errors, maybe display error message
                }
            } catch (error) {
                //console.error('Error attaching category:', error);
                throw error;
            }
        },
        async saveStandToUser() {
            //console.log(this.userStand)
            const token = localStorage.getItem('token');
            const selectedStandId = this.selectedStandId; // Assuming you have a data property to store the selected stand ID

            try {
                const response = await fetch(API_URL + `/user/stand/add/${this.userStand.id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ stand_id: selectedStandId }),
                });

                if (response.ok) {
                    // Refresh the list of user stands after adding a new stand
                    this.standUser(this.userStand);
                    this.$notify({
                        message: "Stand Added",
                        icon: "add_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'success',
                    });
                    
                    location.reload();
                } else if (response.status === 409) {
                    //console.error('Stand already added');
                    this.$notify({
                        message: "Stand Already Added",
                        icon: "add_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'info',
                    });
                } else {
                    //console.error('Failed to add stand');
                    this.$notify({
                        message: "Failed to Add Stand",
                        icon: "add_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger',
                    });
                }
            } catch (error) {
                //console.error('Error adding stand:', error);
                throw error;
            }
        },
        historiqueUser(user){
            this.histoUser = user;
        },
        formattedDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        },
        editnewPassword(value){
            this.userID = value
        },
        async saveNewPassword(){
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + `/user/modify/password/${this.userID}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ newPassword: this.newPassword }),
                });

                if (response.ok) {
                    // Refresh the list of user stands after adding a new stand
                    this.$notify({
                        message: "Password Modified",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'success',
                    });
                    this.getUsers();
                    document.getElementById('closeModal').click();
                    setTimeout(() => {
                        location.reload();
                    }, 2000);
                    
                } else if (response.status === 409) {
                    //console.error('Stand already added');
                    this.$notify({
                        message: "Password Modified Failed",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'info',
                    });
                } else {
                    //console.error('Failed to add stand');
                    this.$notify({
                        message: "Password Modified Failed",
                        icon: "edit_alert",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'info',
                    });
                }
            } catch (error) {
                //console.error('Error adding stand:', error);
                throw error;
            }
        }

    }
};
</script>