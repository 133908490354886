<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Newsletter</h4>
                        <p class="category">List of mail joined the newsletter</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <md-table v-model="emails" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Email">{{ item.email }} </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL } from '../../services/apis';
export default {
    name: "MailingsPage",
    data() {
        return {
            selected: [],
            emails: [],
        };
    },
    created() {
        this.getEmails();

    },
    methods: {
        async getEmails() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/mailing/newsletter/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    //console.log(data);
                    this.emails =  data.data;
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching users:', error);
                throw error;
            }
        },
    }
};
</script>
