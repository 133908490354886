<template>

    <div class="content">
        <div class="text-center">
            <md-button class="md-info" data-bs-toggle="modal" data-bs-target="#AddCouponModal">Add
                Coupon<md-icon>add</md-icon></md-button>
        </div>
        <div class="modal fade" id="AddCouponModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Coupon</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <form @submit.prevent="saveAddCoupon">
                                <div class="mb-3">
                                    <label for="code" class="form-label">Code</label>
                                    <input type="text" class="form-control" id="code" v-model="form.code" required>
                                </div>
                                <div class="mb-3">
                                    <label for="discount" class="form-label">Discount (%)</label>
                                    <input type="text" class="form-control" id="discount" v-model="form.discount"
                                        required>
                                </div>
                                <div class="mb-3">
                                    <label for="Date" class="form-label">Expiry Date</label>
                                    <input type="date" class="form-control" id="Date" v-model="form.expiry_date"
                                        :min="todayDate" required>
                                </div>
                                <button type="submit" class="btn bg-black text-white w-100">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Coupons</h4>
                        <p class="category">List of coupons</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <md-table v-model="coupons" table-header-color="black">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Code">{{ item.code }} </md-table-cell>
                                    <md-table-cell md-label="Discount">{{ item.discount }} </md-table-cell>
                                    <md-table-cell md-label="Expiry Date">{{ item.expiry_date }} </md-table-cell>
                                    <md-table-cell md-label="Usage limit">
                                        <span class="badge bg-success" v-if="item.usage_limit == 1">ONE TIME</span>
                                        <span class="badge bg-danger" v-if="item.usage_limit == 0">MANY TIMES</span>
                                    </md-table-cell>
                                    <md-table-cell md-label="Nbr usage">{{ item.usage_count }} </md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-danger" @click="onCouponDelete(item)">
                                            <md-icon>delete</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { API_URL } from '../../services/apis';

export default {
    name: "CouponsPage",
    data() {
        return {
            selected: [],
            coupons: [],
            form: {
                code: '',
                discount: 0,
                expiry_date: ''
            },
            todayDate:moment().format('YYYY-MM-DD')
        };
    },
    created() {
        this.getCoupons();
        
    },
    methods: {
        async getCoupons() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/coupons/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    this.coupons = data.data;
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching users:', error);
                throw error;
            }
        },
        async saveAddCoupon() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/coupons/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                    body: JSON.stringify(this.form),
                });
                const responseData = await response.json();
                if (!response.ok) {
                    throw new Error(responseData.message || 'Failed to save user');
                }
                this.getCoupons();
                this.$notify({
                    message:
                    "Coupon Added",
                    icon: "add_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success',
                });
                document.getElementById('closeModal').click();
            } catch (error) {
                //console.error('Error adding stand:', error);
                throw error;
            }
        },
        async onCouponDelete(couponToDelete) {
            const tokenLc = localStorage.getItem('token');
            try {
                await fetch(API_URL + `/coupons/delete/${couponToDelete.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });
                this.getCoupons();
            } catch (error) {
                //console.error('Error removing coupon:', error);
                throw error;
            }
        }
    }
};
</script>
