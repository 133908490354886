<template>

    <div class="content">

        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header data-background-color="black">
                        <h4 class="title">Store</h4>
                        <p class="category">Form to update your store information initial !</p>
                    </md-card-header>
                    <md-card-content>
                        <div>
                            <form @submit.prevent="onSaveInfosStore">
                                <div class="mb-3">
                                    <label for="name" class="form-label">Adresse Store</label>
                                    <input type="text" class="form-control" id="name" v-model="form.adresse_store" required>
                                </div>
                                <div class="mb-3">
                                    <label for="location" class="form-label">Phone Store</label>
                                    <input type="text" class="form-control" id="location" v-model="form.phone_store"
                                        required>
                                </div>
                                <div class="mb-3">
                                    <label for="location" class="form-label">Email Store</label>
                                    <input type="text" class="form-control" id="location" v-model="form.email_store"
                                        required>
                                </div>
                                <div class="mb-3">
                                    <label for="location" class="form-label">Instagram Store</label>
                                    <input type="text" class="form-control" id="location" v-model="form.instagram_store"
                                        required>
                                </div>
                                <div class="mb-3">
                                    <label for="location" class="form-label">Facebook Store</label>
                                    <input type="text" class="form-control" id="location" v-model="form.facebook_store"
                                        required>
                                </div>
                                <div class="mb-3">
                                    <label for="location" class="form-label">Whatsapp Store</label>
                                    <input type="text" class="form-control" id="location" v-model="form.whatsapp_store"
                                        required>
                                </div>
                                <button type="submit" class="btn bg-black text-white w-100">Submit</button>
                            </form>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { API_URL } from '../../../services/apis';

export default {
    name: "StocksPage",
    data() {
        return {
            selected: [],
            stocks: [],
            form: {
                id: -1,
                adresse_store: '',
                phone_store: '',
                logo_store: '',
                logo_footer: '',
                email_store: '',
                instagram_store: '',
                facebook_store: '',
                whatsapp_store: '',
            }
        };
    },
    created() {
        this.getInfosStore();
    },
    methods: {
        async getInfosStore() {
            const tokenLc = localStorage.getItem('token');
            try {
                const response = await fetch(API_URL + '/settings/index', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${tokenLc}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    //console.log(data);
                    this.form.id = data.data[0].id;
                    this.form.adresse_store = data.data[0].adresse_store;
                    this.form.phone_store = data.data[0].phone_store;
                    this.form.logo_store = data.data[0].logo_store;
                    this.form.logo_footer = data.data[0].logo_footer;
                    this.form.email_store = data.data[0].email_store;
                    this.form.instagram_store = data.data[0].instagram_store;
                    this.form.facebook_store = data.data[0].facebook_store;
                    this.form.whatsapp_store = data.data[0].whatsapp_store;
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                //console.error('Error fetching users:', error);
                throw error;
            }
        },
        async onSaveInfosStore(){
            const tokenLc = localStorage.getItem('token');
            try {
                await fetch(API_URL+`/settings/update/${this.form.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLc}`,
                },
                body: JSON.stringify(this.form),
                });
                this.getInfosStore();
                this.$notify({
                    message:
                    "Infos Updated",
                    icon: "edit_alert",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'warning',
                });
            } catch (error) {
                //console.error('Error updating stand:', error);
                throw error;
            }
        },
    }
};
</script>